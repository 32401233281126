export default () => ({
  items: [],
  get filteredDates() {
    return this.items.filter((item) => {
      let selected = true    
      if (this.filter_year.length && !this.filter_year.every((year) => item.year.includes(String(year)))) selected = false
      return selected

    })
  },

 get filter_year() {
    return this.$store.url.getParam('year') ? (Array.isArray(this.$store.url.getParam('year')) ? this.$store.url.getParam('year') : [this.$store.url.getParam('year')]) : []
  },
  setDates(items = []) {
    this.items = [...items]
  }
})
